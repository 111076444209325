import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import members_information from './module/data/members_information'
import business_owners from './module/data/business_owners'
import bank_depositories from './module/data/bank_depositories'
import deposit_slips from './module/data/deposit_slips'
import members_payments from './module/data/members_payments'
import transaction_month from './module/data/transaction_month'

const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'DAVSUR CHAMBER',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    members_information,
    business_owners,
    bank_depositories,
    deposit_slips,
    members_payments,
    transaction_month,
  },
})
